import React from 'react';
import styled from 'styled-components';

import { LinkWrapper, Loader } from '@components';
// Import Text form @component/typography in order to overcome circular dependency issue that causes errors in CMS
// TODO check the circular dependency issue
import { Text } from '@components/typography';
import { Box, Button } from '@core';

const CardContainer = styled(Box)`
  text-align: center;
  background: ${({ theme }) => theme.colors.planCardBackground || '#EAF2FF'};
`;

const RegularPrice = styled(Text.Body3)`
  text-decoration: line-through;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.darkBlue_60};
`;

const Sticker = ({ badgeText }) => (
  <Box
    height={96}
    width={96}
    borderRadius="50%"
    position="absolute"
    backgroundColor="danger"
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    right={{ _: -8, sm: -30 }}
    top={-30}
  >
    <Text.Body4 as="span" textAlign="center" color="white">
      {badgeText}
    </Text.Body4>
  </Box>
);

const DealsPlanCard = ({
  clickHandler,
  isLoading,
  duration,
  currency,
  price,
  pricePeriod,
  badgeText,
  regularPricePerMonth,
  billingDescription,
  highlightLabel,
  couponText,
  button,
}) => (
  <Box
    width={{ _: 300, md: 330 }}
    backgroundColor={highlightLabel && 'success'}
    borderRadius="md"
    position="relative"
  >
    {badgeText && <Sticker badgeText={badgeText} />}

    <CardContainer
      minHeight={{ _: 340, md: 370 }}
      borderRadius="md"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      onClick={clickHandler}
      px={24}
      pt={32}
      pb={24}
    >
      {isLoading ? (
        <Box margin="auto">
          <Loader isLoading />
        </Box>
      ) : (
        <React.Fragment>
          <Text.Subheading2 color="darkBlue" whiteSpace="pre-line">
            {duration}
          </Text.Subheading2>

          <Text.Body2 color="darkBlue" fontSize={14} textAlign="center">
            {couponText}
          </Text.Body2>

          <Box my={8}>
            <Box
              mb={8}
              display="flex"
              justifyContent="center"
              alignItems="baseline"
              flexWrap="wrap"
            >
              <Text.Body3Strong color="darkBlue">{currency}</Text.Body3Strong>
              <Text.Heading1 color="darkBlue" $fontSize={72} lineHeight="80px">
                {price}
              </Text.Heading1>
              <Text.Body3Strong color="darkBlue">{pricePeriod}</Text.Body3Strong>
            </Box>
            <RegularPrice>{regularPricePerMonth}</RegularPrice>
          </Box>

          <Box my={16}>
            <Text.Body4 color="darkBlue">{billingDescription}</Text.Body4>
          </Box>

          {button && (
            <LinkWrapper link={button?.link} withParams>
              <Button fullWidth size="large" {...button.buttonProps}>
                {button?.title}
              </Button>
            </LinkWrapper>
          )}
        </React.Fragment>
      )}
    </CardContainer>

    {highlightLabel && (
      <Box px={24} minHeight={60} display="flex" alignItems="center" justifyContent="center">
        <Text.Body4Strong textAlign="center" color="white">
          {highlightLabel}
        </Text.Body4Strong>
      </Box>
    )}
  </Box>
);

export default DealsPlanCard;
